import { useForceUpdate } from 'signer-app/utils/use-force-update';
import React from 'react';
import { HyperlinkField, TextField } from 'signer-app/types/editor-types';
import { DEFAULT_FONT_SIZE, MIN_FONT_SIZE } from 'signer-app/signature-request';
import { FieldUpdate } from 'signer-app/signer-signature-document/types';

export function isMultilineField(field: TextField | HyperlinkField): boolean {
  return field.height >= (field.fontSize || DEFAULT_FONT_SIZE) * 2;
}

export function getLines(hiddenDOM: HTMLTextAreaElement, value: string) {
  const contentLines = [];
  const lines = value.replace(/\r/g, '').split('\n');

  const { height, minHeight } = hiddenDOM.style;
  hiddenDOM.style.height = '0';
  hiddenDOM.style.minHeight = '0';

  hiddenDOM.value = 'A';
  // The `*1.75` was added to account for Chinese characters.  singleLineHeight
  // is measured against the latter `A`, but Chinese characters can be taller
  // than `A`, which was causing an infinite loop below
  const singleLineHeight = hiddenDOM.scrollHeight * 1.75;

  for (let i = 0; i < lines.length; i++) {
    const words = lines[i].split(' ');
    const line = [];

    while (words.length > 0) {
      line.push(words.shift());
      hiddenDOM.value = line.join(' ');

      // If (it looks like the hiddenDOM is line wrapped) {
      if (hiddenDOM.scrollHeight > singleLineHeight * 1) {
        // If a single "word" is too wide, cut letters until it fits
        if (line.length === 1) {
          let left;
          // line definitely contains something, because the first line of the
          // while loop pushes to it.
          let right = line[0]!;

          while (right.length > 0) {
            left = right;
            right = '';

            hiddenDOM.value = left;
            while (
              hiddenDOM.scrollHeight > singleLineHeight &&
              left.length > 0
            ) {
              right = left.substr(-1) + right;
              left = left.substr(0, left.length - 1);
              hiddenDOM.value = left;
            }
            if (left.length === 0) {
              throw new Error('Unable to trim input');
            }
            contentLines.push(left);
          }
          line.length = 0;
        } else {
          const lastWord = line.pop();
          contentLines.push(line.join(' '));
          line.length = 0;
          line.push(lastWord);
        }
      }
    }
    contentLines.push(line.join(' '));
  }

  hiddenDOM.style.height = height;
  hiddenDOM.style.minHeight = minHeight;

  return contentLines.join('\n').trim();
}
export function calcFontSizeAdjustment(
  fontSize: number,
  hiddenDOM: HTMLTextAreaElement,
  textDOM: HTMLTextAreaElement,
  isMultiline: boolean | undefined,
) {
  let newSize = fontSize;
  // Shrink the font size until everything fits or we reach the minimum size
  hiddenDOM.style.fontSize = `${fontSize}px`;
  while (
    newSize > MIN_FONT_SIZE &&
    ((isMultiline && hiddenDOM.scrollHeight > textDOM.clientHeight) ||
      (!isMultiline && hiddenDOM.scrollWidth > textDOM.clientWidth))
  ) {
    newSize--;
    const { scrollHeight: oldHeight, scrollWidth: oldWidth } = hiddenDOM;
    hiddenDOM.style.fontSize = `${newSize}px`;
    // in some cases (ex: CJK fonts on CJK systems) a lower fontSize value doesn't change the
    // rendered font size. This early break is meant to catch these scenarios and prevent other
    // issues downstream (ex: fontSize being reduced to MIN_FONT_SIZE and isMultiline returning
    // true when the real font size is 11px)
    if (
      oldHeight === hiddenDOM.scrollHeight &&
      oldWidth === hiddenDOM.scrollWidth
    ) {
      newSize += 1;
      break;
    }
  }

  return newSize;
}

export function trimOverflow(
  value: string,
  hiddenDOM: HTMLTextAreaElement,
  textDOM: HTMLTextAreaElement,
  isMultiline: boolean | undefined,
) {
  // Remove 1 character at a time until everything fits.
  let trimmedValue = value;
  while (
    trimmedValue.length > 1 &&
    ((isMultiline && hiddenDOM.scrollHeight > textDOM.clientHeight) ||
      (!isMultiline && hiddenDOM.scrollWidth > textDOM.clientWidth))
  ) {
    trimmedValue = trimmedValue.substr(0, trimmedValue.length - 1);
    hiddenDOM.value = trimmedValue;
  }
  return trimmedValue;
}

export function useSignerTextInput(
  fieldData: TextField,
  textRef: undefined | React.RefObject<HTMLTextAreaElement | undefined>,
  // WARNING: hidden assumes it has a text field that is styled exactly the same
  // as textRef (ie fontSize, fontFamily, margin, padding, etc), except that it
  // has 0 width and height and is hidden.
  hidden: undefined | React.RefObject<HTMLTextAreaElement | undefined>,
  onChange: (updates: FieldUpdate) => void,
  locationName: 'review' | 'preview' | 'signer' | 'overlay',
  canTrim = true,
) {
  const forceUpdate = useForceUpdate();
  React.useLayoutEffect(() => {
    // On the first render `textRef` and `hidden` refs aren't available. This
    // force update will quickly force a next render so the text can be
    // adjusted.
    forceUpdate();
  }, [forceUpdate]);
  const [isOverflowing, setOverflow] = React.useState(false);
  const { name } = fieldData;
  const previousValue = React.useRef<string>('');

  const canWrapText = isMultilineField(fieldData);
  React.useEffect(() => {
    let value = fieldData.value || '';
    const originalValue = value;
    if (!textRef || !textRef.current || !hidden || !hidden.current) {
      return;
    }
    const textDOM = textRef.current;
    const hiddenDOM = hidden.current;
    // If we have the same or more text as the previous render, begin fontSize adjustments from
    // fieldData.fontSize to prevent subsequent calls from turning cyclic (ex: 7 -> 8 -> 7, etc.).
    // If we have less content (backspace/input clear), begin from fieldData.originalFontSize in
    // case the user clears the input.
    const startingFontSize =
      value.indexOf(previousValue.current) === 0
        ? fieldData.fontSize || fieldData.originalFontSize
        : fieldData.originalFontSize;
    const fontSize = startingFontSize || DEFAULT_FONT_SIZE;

    const newFontSize = calcFontSizeAdjustment(
      fontSize,
      hiddenDOM,
      textDOM,
      canWrapText,
    );
    const trimmedValue = trimOverflow(value, hiddenDOM, textDOM, canWrapText);
    if (canTrim) {
      value = trimmedValue;
    }
    const lines = canWrapText ? getLines(hiddenDOM, value) : value;

    if (value !== originalValue) {
      onChange({ value });
    }
    if (
      lines !== (fieldData.lines || '') ||
      newFontSize !== fieldData.fontSize
    ) {
      onChange({ fontSize: newFontSize, lines });
    }
    if (!canTrim) {
      setOverflow(trimmedValue !== value);
    }
    previousValue.current = value;
  }, [
    canTrim,
    fieldData.fontSize,
    fieldData.id,
    canWrapText,
    fieldData.lines,
    fieldData.originalFontSize,
    fieldData.value,
    hidden,
    locationName,
    name,
    onChange,
    textRef,
  ]);

  return isOverflowing;
}
